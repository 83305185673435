import { render, staticRenderFns } from "./ExportSpecifications.vue?vue&type=template&id=9a67ee68&scoped=true&"
import script from "./ExportSpecifications.vue?vue&type=script&lang=js&"
export * from "./ExportSpecifications.vue?vue&type=script&lang=js&"
import style0 from "./ExportSpecifications.vue?vue&type=style&index=0&id=9a67ee68&prod&lang=scss&scoped=true&"
import style1 from "./ExportSpecifications.vue?vue&type=style&index=1&id=9a67ee68&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a67ee68",
  null
  
)

export default component.exports